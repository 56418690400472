var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns fixed-page"},[_c('div',{staticClass:"column main-column"},[_c('div',{staticClass:"todos page"},[_c('div',{staticClass:"task-tabs tabs"},[_c('ul',[_c('li',{class:{'is-active': _vm.isTabActive('todos')}},[_c('router-link',{attrs:{"to":{
              name: 'todos',
            }}},[_vm._v(" "+_vm._s(_vm.$t('tasks.current'))+" ")])],1),_c('li',{class:{'is-active': _vm.isTabActive('done')},on:{"click":function($event){return _vm.selectTab('done')}}},[_c('router-link',{attrs:{"to":{
              name: 'todos-tab',
              params: {tab: 'done'}
            }}},[_vm._v(" "+_vm._s(_vm.$t('tasks.done'))+" ("+_vm._s(_vm.displayedDoneTasks.length)+") ")])],1),_c('li',{class:{'is-active': _vm.isTabActive('timesheets')},on:{"click":function($event){return _vm.selectTab('timesheets')}}},[_c('router-link',{attrs:{"to":{
              name: 'todos-tab',
              params: {tab: 'timesheets'}
            }}},[_vm._v(" "+_vm._s(_vm.$t('timesheets.title'))+" ")])],1)])]),_c('div',{staticClass:"flexrow"},[(!_vm.isTabActive('done'))?_c('search-field',{ref:"todos-search-field",class:{
            'search-field': true,
            'flexrow-item': true
          },attrs:{"can-save":true},on:{"change":_vm.onSearchChange,"save":_vm.saveSearchQuery}}):_vm._e(),_c('span',{staticClass:"flexrow-item push-right"}),_c('span',{staticClass:"flexrow-item"}),_c('combobox',{staticClass:"flexrow-item",attrs:{"label":_vm.$t('main.sorted_by'),"options":_vm.sortOptions,"locale-key-prefix":"tasks.fields."},model:{value:(_vm.currentSort),callback:function ($$v) {_vm.currentSort=$$v},expression:"currentSort"}})],1),(_vm.isTabActive('todos') || _vm.isTabActive('timesheets'))?_c('div',{staticClass:"query-list"},[_c('search-query-list',{attrs:{"queries":_vm.todoSearchQueries},on:{"change-search":_vm.changeSearch,"remove-search":_vm.removeSearchQuery}})],1):_vm._e(),(_vm.isTabActive('todos'))?_c('todos-list',{ref:"todo-list",attrs:{"tasks":_vm.sortedTasks,"is-loading":_vm.isTodosLoading,"is-error":_vm.isTodosLoadingError,"selection-grid":_vm.todoSelectionGrid},on:{"scroll":_vm.setTodoListScrollPosition}}):_vm._e(),(_vm.isTabActive('done'))?_c('div'):_vm._e(),(_vm.isTabActive('done'))?_c('todos-list',{ref:"done-list",attrs:{"tasks":_vm.displayedDoneTasks,"is-loading":_vm.isTodosLoading,"is-error":_vm.isTodosLoadingError,"done":true}}):_vm._e(),(_vm.isTabActive('timesheets'))?_c('timesheet-list',{ref:"timesheet-list",attrs:{"tasks":_vm.loggableTodos,"done-tasks":_vm.loggableDoneTasks,"is-loading":_vm.isTodosLoading,"is-error":_vm.isTodosLoadingError,"time-spent-map":_vm.timeSpentMap,"time-spent-total":_vm.timeSpentTotal,"hide-done":_vm.todosSearchText.length > 0 || _vm.loggableDoneTasks.length === 0},on:{"date-changed":_vm.onDateChanged,"time-spent-change":_vm.onTimeSpentChange,"set-day-off":_vm.onSetDayOff,"unset-day-off":_vm.onUnsetDayOff}}):_vm._e()],1)]),(_vm.nbSelectedTasks === 1)?_c('div',{staticClass:"column side-column"},[_c('task-info',{attrs:{"task":Object.values(_vm.selectedTasks)[0]}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }