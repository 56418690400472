var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
  'modal': true,
  'is-active': _vm.active
}},[_c('div',{staticClass:"modal-background",on:{"click":function($event){return _vm.$emit('cancel')}}}),_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"box content"},[(_vm.isEditing)?_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("tasks.change_preview"))+" ")]):_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("tasks.add_preview"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("tasks.select_preview_file"))+" ")]),_c('file-upload',{ref:"preview-field",attrs:{"label":_vm.$t('main.csv.upload_file'),"accept":_vm.extensions,"multiple":true},on:{"fileselected":_vm.onFileSelected}}),(_vm.isError)?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.$t("tasks.add_preview_error"))+" ")]):_vm._e(),_c('p',{staticClass:"has-text-right"},[_c('a',{class:{
            button: true,
            'is-primary': true,
            'is-loading': _vm.isLoading,
            'is-disabled': _vm.forms == undefined
          },on:{"click":function($event){return _vm.$emit('confirm')}}},[_vm._v(" "+_vm._s(_vm.$t("main.confirmation"))+" ")]),_c('button',{staticClass:"button is-link",on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t("main.cancel"))+" ")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }