var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns fixed-page"},[_c('div',{staticClass:"column main-column"},[_c('div',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onBodyScroll),expression:"onBodyScroll"}],ref:"body",staticClass:"notifications page"},[(!_vm.loading.notifications && (!_vm.notifications || _vm.notifications.length === 0))?_c('div',{staticClass:"empty-list has-text-centered"},[_vm._v(" "+_vm._s(_vm.$t('notifications.no_notifications'))+" ")]):_vm._e(),(_vm.loading.notifications)?_c('div',{staticClass:"has-text-centered"},[_c('spinner')],1):_vm._e(),_vm._l((_vm.notifications),function(notification){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading.notifications),expression:"!loading.notifications"}],key:notification.id,class:{
        notification: true,
        unread: !notification.read,
        selected: notification.id === _vm.currentNotificationId
      },on:{"click":function($event){return _vm.onNotificationSelected(notification)}}},[_c('div',{staticClass:"flexrow notification-line"},[_c('div',{staticClass:"flexrow-item"},[_c('div',{staticClass:"flexrow"},[(_vm.isMention(notification))?_c('at-sign-icon',{staticClass:"icon flexrow-item"}):_vm._e(),(_vm.isComment(notification))?_c('message-square-icon',{staticClass:"icon flexrow-item"}):_vm._e(),(_vm.isAssignation(notification))?_c('user-icon',{staticClass:"icon flexrow-item"}):_vm._e(),_c('task-type-name',{staticClass:"task-type-name",attrs:{"task-type":_vm.buildTaskTypeFromNotification(notification),"production-id":notification.project_id}})],1),_c('div',{staticClass:"mt1"},[(notification.change)?_c('validation-tag',{staticClass:"validation-tag flexrow-item mt1",attrs:{"task":_vm.buildTaskFromNotification(notification)}}):_vm._e()],1)]),_c('div',{staticClass:"flexrow-item comment-content"},[_c('div',[_c('div',{staticClass:"notification-info flexrow"},[(_vm.personMap[notification.author_id])?_c('people-avatar',{staticClass:"flexrow-item",attrs:{"person":_vm.personMap[notification.author_id],"size":30}}):_vm._e(),_c('router-link',{staticClass:"person-name flexrow-item",attrs:{"to":{
              name: 'person',
              params: {person_id: notification.author_id}
            }}},[_vm._v(" "+_vm._s(_vm.personName(notification))+" ")]),(_vm.isComment(notification))?_c('span',{staticClass:"explaination flexrow-item"},[_vm._v(" "+_vm._s(_vm.$t('notifications.commented_on'))+" ")]):_vm._e(),(_vm.isAssignation(notification))?_c('span',{staticClass:"explaination flexrow-item"},[_vm._v(" "+_vm._s(_vm.$t('notifications.assigned_you'))+" ")]):_vm._e(),(_vm.isMention(notification))?_c('span',{staticClass:"explaination flexrow-item"},[_vm._v(" "+_vm._s(_vm.$t('notifications.mention_you_on'))+" ")]):_vm._e(),_c('router-link',{staticClass:" flexrow-item",attrs:{"to":_vm.entityPath(notification)}},[_vm._v(" "+_vm._s(notification.project_name)+" / "+_vm._s(notification.full_entity_name)+" ")])],1)]),((_vm.isComment(notification) || _vm.isMention(notification)) && notification.comment_text)?_c('div',{staticClass:"comment-text",domProps:{"innerHTML":_vm._s(_vm.renderComment(notification.comment_text, notification.mentions,
                             _vm.personMap))}}):_vm._e(),(notification.preview_file_id)?_c('div',{staticClass:"flexrow"},[_c('paperclip-icon',{staticClass:"icon flexrow-item"}),(notification.preview_file_id)?_c('div',{staticClass:"thumbnail-picture-wrapper flexrow-item"},[_c('entity-thumbnail',{attrs:{"entity":{preview_file_id: notification.preview_file_id},"height":40}})],1):_vm._e()],1):_vm._e(),((_vm.isComment(notification) || _vm.isMention(notification)) && !notification.comment_text)?_c('div',{staticClass:"comment-text"},[_vm._v(" "+_vm._s(_vm.$t('comments.empty_text'))+" ")]):_vm._e(),_c('div',{staticClass:"date flexrow"},[_c('span',{staticClass:"flexrow-item"},[_vm._v(" "+_vm._s(_vm.formatDate(notification.created_at))+" ")])])])])])})],2)]),(_vm.currentTask)?_c('div',{staticClass:"column side-column is-hidden-mobile hide-small-screen"},[_c('task-info',{attrs:{"task":_vm.currentTask,"is-loading":_vm.loading.currentTask}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }