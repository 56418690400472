var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(!_vm.minimized)?_c('span',[(!_vm.isStatic && !_vm.isCurrentUserClient)?_c('router-link',{staticClass:"tag dynamic",style:({
        background: _vm.backgroundColor,
        color: _vm.color
    }),attrs:{"to":_vm.taskPath(_vm.task)}},[_vm._v(" "+_vm._s(_vm.taskStatus.short_name)+" ")]):_c('span',{staticClass:"tag",style:({
        background: _vm.backgroundColor,
        color: _vm.color,
        cursor: _vm.cursor
      })},[_vm._v(" "+_vm._s(_vm.taskStatus.short_name)+" ")]),(_vm.isPriority && !_vm.isCurrentUserClient)?_c('span',{staticClass:"priority"},[_vm._v(" "+_vm._s(_vm.priority)+" ")]):_vm._e()],1):_c('span',[(!_vm.isStatic && !_vm.isCurrentUserClient)?_c('router-link',{staticClass:"tag dynamic",style:({
        background: _vm.backgroundColor,
        color: _vm.color
    }),attrs:{"to":_vm.taskPath(_vm.task)}}):_c('span',{staticClass:"tag",style:({
        background: _vm.backgroundColor,
        color: _vm.color,
        cursor: _vm.cursor
      })})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }