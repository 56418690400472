import { render, staticRenderFns } from "./BrowsingBar.vue?vue&type=template&id=42b3438d&scoped=true&"
import script from "./BrowsingBar.vue?vue&type=script&lang=js&"
export * from "./BrowsingBar.vue?vue&type=script&lang=js&"
import style0 from "./BrowsingBar.vue?vue&type=style&index=0&id=42b3438d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42b3438d",
  null
  
)

export default component.exports